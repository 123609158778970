// HomePage.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import NewAccountModal from './NewAccountModal';
import { Box, Container, IconButton, Grid, } from '@mui/material';

//const apiUrl = 'http://localhost:3001'
 const apiUrl = ''


function HomePage() {
  const [userId, setUserId] = useState(1);
  const [accounts, setAccounts] = useState([]);
  const [showNewAccountModal, setShowNewAccountModal] = useState(false);

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/accounts/${userId}`);
        if (!response.ok) {
          console.error('Failed to fetch accounts');
        }
        const data = await response.json();
        setAccounts(data);
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };
    fetchAccounts();
  }, []);

  const getProfilePicture = (url) => {
    try {
      return require(`${url}`);
    } catch (error) {
      console.error(`Failed to load image for ${url}:`, error);
      return null; // Provide a fallback or placeholder image
    }
  };

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <h1>Dino Banque</h1>
        <IconButton onClick={() => setShowNewAccountModal(true)}>
          <SettingsIcon />
        </IconButton>
      </Box>

      <Box mt={2} border="1px solid #ccc" borderRadius={2} p={2}>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          gap={2} // Optional gap between items
        >
          {accounts.map((account) => (
            <Box
            key={account.id}
            flex="1 1 calc(33.333% - 16px)" // Default for large screens: 3 columns
            maxWidth="calc(33.333% - 16px)"
            minWidth="200px"
            display="flex"
            flexDirection="column" // Default for vertical alignment
            alignItems="center"
            justifyContent="center"
            sx={{
              '@media (max-width: 600px)': {
                flex: '1 1 100%', // Full width on small screens
                maxWidth: '100%',
                flexDirection: 'column', // Ensure vertical alignment
              },
            }}
          >
            <Link href={`/account/${account.id}`} style={{ textDecoration: 'none' }}>
              <img
                src={account.avatar}
                alt={`${account.name}'s profile`}
                style={{ width: '100px', height: '100px', borderRadius: '50%' }}
              />
            </Link>
            <Link to={`/account/${account.id}`} style={{ marginTop: '8px' }}>
              {account.name}
            </Link>
          </Box>
          ))}
        </Box>
      </Box>


      <NewAccountModal
        open={showNewAccountModal}
        onClose={() => setShowNewAccountModal(false)}
        onAddAcount={(newAccount) => setAccounts([...accounts, newAccount])}
      />
    </Container>
  );
}

export default HomePage;
