import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import { AuthProvider } from "react-oidc-context";

const cognitoAuthConfig = {
  authority: "https://cognito-idp.us-east-2.amazonaws.com/us-east-2_FHKFzm8os",
  client_id: "2sue93uhtt8kn9491nk03nes0g",
  redirect_uri: "https://dinobank.co/",
  response_type: "code",
  scope: "email openid phone",
};

//serviceWorker.register(); // Ensure this is set to register

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider {...cognitoAuthConfig}>
    <App />
    </AuthProvider>
  </React.StrictMode>
);
