// NewAccountModal.js
import React, { useState } from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button} from '@mui/material';

const baseUrl = 'http://localhost:3001'
//const baseUrl = ''


function NewAccountModal({ open, onClose, onAddAcount, userId }) {
  const [newAccountName, setNewAccountName] = useState('');

  const handleSave = async () => {
    const newAccount = {
      id: Date.now(),
      name: newAccountName,
      userId: 1
    };

    await saveNewAccountToServer(newAccount);

    onAddAcount(newAccount);
    onClose();
  };

  const saveNewAccountToServer = async (newAccount) => {
    try {
      const response = await fetch(`${baseUrl}/api/accounts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin' : '*',
        },
        body: JSON.stringify(newAccount),
      });
      if (!response.ok) {
        console.error('Failed to save newAccount: ', response);
      }else{
        console.log('New account saved successfully');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>New account</DialogTitle>
      <DialogContent>
      <TextField
          type="text"
          label="New account name"
          value={newAccountName}
          onChange={(e) => setNewAccountName(e.target.value)}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NewAccountModal;
