// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import AccountPage from './components/AccountPage';
import { useAuth } from "react-oidc-context";


function App() {
  const auth = useAuth();

  const signOutRedirect = () => {
    const clientId = "2sue93uhtt8kn9491nk03nes0g";
    const logoutUri = "https://dinobank.co/";
    const cognitoDomain = "https://dinobank.auth.us-east-2.amazoncognito.com";
    window.location.href = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${encodeURIComponent(logoutUri)}`;
  };

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Encountering error... {auth.error.message}</div>;
  }

  return (
    <Router>
      {auth.isAuthenticated ? (
        <div>
          <header>
            <p>Welcome, {auth.user?.profile.email}!</p>
            <button onClick={() => auth.removeUser()}>Sign out</button>
          </header>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/account/:accountId" element={<AccountPage />} />
          </Routes>
        </div>
      ) : (
        <div>
          <p>You are not authenticated.</p>
          <button onClick={() => auth.signinRedirect()}>Sign in</button>
          <button onClick={() => signOutRedirect()}>Sign out</button>
        </div>
      )}
    </Router>
  );
}

export default App;
